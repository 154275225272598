import React from "react"

/**
 * CoverBottom
 */
export default () => {
  return (
    <div className="section is-p0 m-landing__bot t-absolute">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column has-text-white has-text-centered">
            <i className="fas fa-caret-down fa-3x tw-animate-bounce"></i>
          </div>
        </div>
      </div>
    </div>
  )
}
